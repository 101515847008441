import BigNumber from 'bignumber.js'

BigNumber.prototype.str = function () {
  return this.toFixed(0)
}

BigNumber.prototype.bigInt = function () {
  return BigInt(this.toFixed(0))
}

export function isInt(value: number | string) {
  const x = parseFloat(value.toString())
  return (x | 0) === x
}
