type Config = {
  awsS3Api: string
  nftApi: string
}

const configs: Config = {
  awsS3Api: process.env.NEXT_PUBLIC_AWS_S3_URL as string,
  nftApi: process.env.NEXT_PUBLIC_NFT_API_URL as string,
}

export default configs
