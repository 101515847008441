type Config = {
  sp3Origin: string
  origin: string
  mission: string
  launchpad: string
}

const configs: Config = {
  origin: process.env.NEXT_PUBLIC_ORIGIN as string,
  sp3Origin: process.env.NEXT_PUBLIC_SP3_ORIGIN_URL as string,
  mission: process.env.NEXT_PUBLIC_MISSION_URL as string,
  launchpad: process.env.NEXT_PUBLIC_LAUNCHPAD_URL as string,
}

export default configs
