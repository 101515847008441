type Config = {
  regen721Contract: string
  a8TokenContract: string
}

const configs: Config = {
  regen721Contract: process.env
    .NEXT_PUBLIC_REGEN_721_CONTRACT_ADDRESS as string,
  a8TokenContract: process.env.NEXT_PUBLIC_A8_TOKEN_CONTRACT_ADDRESS as string,
}

export default configs
