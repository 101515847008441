type Config = {
  appRegion: string
  whitelistPath: string
  metadataPath: string
}

const configs: Config = {
  appRegion: process.env.NEXT_PUBLIC_AWS_S3_REGION as string,
  whitelistPath: process.env
    .NEXT_PUBLIC_AWS_S3_WHITELIST_REGEN_PATH_PATTERN as string,
  metadataPath: process.env
    .NEXT_PUBLIC_AWS_S3_METADATA_REGEN_PATH_PATTERN as string,
}

export default configs
