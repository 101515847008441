import { PropsWithChildren } from 'react'
import {
  RainbowKitProvider as RainbowKit,
  darkTheme,
} from '@rainbow-me/rainbowkit'

import '@rainbow-me/rainbowkit/styles.css'

const theme = darkTheme({
  accentColor: 'rgba(255, 255, 255, 0.12)',
})
theme.colors.connectButtonBackground = '#0FDBD1'
theme.colors.connectButtonText = '#1E1E1E'
theme.radii.connectButton = '4px'

function RainbowKitProvider({ children }: PropsWithChildren) {
  return (
    <RainbowKit theme={theme} modalSize="compact">
      {children}
    </RainbowKit>
  )
}

export default RainbowKitProvider
