/*
 * Do not use short import for this file
 */

import {
  Fragment,
  type Key,
  type PropsWithChildren,
  type ReactNode,
} from 'react'

import { notification } from 'antd'

import useIsMobile from '../hooks/system/useIsMobile'

import {
  ArgsProps,
  NotificationInstance,
} from 'antd/lib/notification/interface'

interface ArgsNotifyProps extends ArgsProps {
  message: ReactNode
  description?: ReactNode
}

type NotifyActionFn = (config: ArgsNotifyProps) => void
type NotifyDestroyFn = (key?: Key) => void

interface NotifyInstance extends NotificationInstance {
  success: NotifyActionFn
  error: NotifyActionFn
  warning: NotifyActionFn
  destroy: NotifyDestroyFn
}

const types: (keyof NotifyInstance)[] = [
  'success',
  'error',
  'warning',
  'destroy',
]

//----------------------------------Notify Provider-----------------------------

export const notify = {} as NotifyInstance

type NotifyProviderProps = {
  prefixCls?: string
} & PropsWithChildren

function NotifyProvider({
  children,
  prefixCls = 'space-3',
}: NotifyProviderProps) {
  const isMobile = useIsMobile()

  const [api, contextHolder] = notification.useNotification({
    maxCount: 6,
    stack: false,
  })

  for (const key of types) {
    switch (key) {
      case 'destroy': {
        notify.destroy = (config) => api[key](config)
        break
      }

      default: {
        notify[key] = ({ message, ...rest }) => {
          return api[key]({
            placement: !isMobile ? 'topRight' : 'top',
            ...rest, //rest can override placement
            className: `${prefixCls}-notification-notice-${key}-outline`,
            message: (message as string).toUpperCase(),
          })
        }
        break
      }
    }
  }

  return (
    <Fragment>
      {children}
      {contextHolder}
    </Fragment>
  )
}

export default NotifyProvider
