import location from './location.config'
import walletConnect from './wallet-connect.config'
import blockscout from './blockscout.config'
import api from './api.config'
import contracts from './contract.config'
import s3 from './s3.config'

const configs = {
  location,
  walletConnect,
  blockscout,
  api,
  contracts,
  s3,
}

export default configs
