import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import * as allWallets from '@rainbow-me/rainbowkit/wallets'
import { ancient8, ancient8Sepolia } from 'viem/chains'

import configs from './'

const {
  metaMaskWallet,
  bitgetWallet,
  coinbaseWallet,
  coin98Wallet,
  okxWallet,
} = allWallets

export const supportedChains =
  process.env.NEXT_PUBLIC_ENV === 'production'
    ? ([ancient8] as const)
    : ([ancient8Sepolia] as const)

export const wagmiConfig = getDefaultConfig({
  appName: 'space3-nft-elite',
  projectId: configs.walletConnect.projectId,
  chains: supportedChains,
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        bitgetWallet,
        coinbaseWallet,
        coin98Wallet,
        okxWallet,
      ],
    },
    {
      groupName: 'All',
      wallets: Object.values(allWallets),
    },
  ],
})
