import { ChainID } from '@/constants/index'

type SupportChain = ChainID.A8

type BlockscoutConfig = {
  basePath?: string
}

type Config = Record<SupportChain, BlockscoutConfig>

const configs: Config = {
  [ChainID.A8]: {
    basePath: process.env.NEXT_PUBLIC_A8_BLOCKSCOUT_URL,
  },
}

export default configs
