// NOTE: Do not use short import for this file
import { ConfigProvider } from 'antd'
import { type ReactNode } from 'react'

import { THEME_DEFAULT } from './theme'
import NotifyProvider from '../providers/notify.provider'

const themeProvider = (node: ReactNode) => {
  return (
    <ConfigProvider
      theme={THEME_DEFAULT}
      prefixCls="space-3"
      iconPrefixCls="space-3-icon"
    >
      <NotifyProvider>{node}</NotifyProvider>
    </ConfigProvider>
  )
}

export default themeProvider
