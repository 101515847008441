import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import { type ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import NProgress from 'nprogress'

import WagmiProvider from '@/providers/wagmi.provider'
import RainbowKitProvider from '@/providers/rainbow-kit.provider'

import themeProvider from '@/theme'

// To make number prototype was run first
import '@/helpers/number'

import 'nprogress/nprogress.css'
import '../../public/antd.min.css'
import '@/styles/globals.scss'

type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactNode) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  return (
    <WagmiProvider>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          {themeProvider(getLayout(<Component {...pageProps} />))}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}
